.pageWrapper {
    margin-top: 54px;
    font-weight: black;
    height: 95vh;
    display: flex;
}

.dataEntryPanel {
   // flex: 1;
   width: 65%;
}

.previewPanel {
    // position: fixed;
    // z-index: 2;
    // width: 45vw;;
    // height: 100%;
    width: 100%;
}

.pdf-block {
    // display: flex;
    // flex: 1;
    // justify-content: flex-end;
    // position: sticky;
    width: 35%;
    position: fixed;
    right: 0;
}

.form-loader {
    display: flex;
    justify-content: flex-end;
    position: sticky;
}

.parent-stepper {
    width: 40%;
    max-height: 500px;
    height: 100%;
    overflow: auto;
}

.parent-stepper::-webkit-scrollbar {
    width: 5px;
}
      
.parent-stepper::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
      
.parent-stepper::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
      
.parent-stepper::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.step-content {
    border: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    max-height: 500px;
    height: 100%;
    overflow: auto;
}

.step-content-width {
    width: 60%;
}

.step-content::-webkit-scrollbar {
    width: 5px;
}
      
.step-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}
      
.step-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}
      
.step-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.step-content .MuiCollapse-wrapperInner {
    padding-right: 20px;
    width: 715px !important;
}

.book-list {
    width: 70%;
}

.btn-collection {
    display: flex;
    gap: 32px;
    width: 70%;
}

.previewPanel iframe {
    // min-height: 95vh;
    // min-width: 45vw;
    width: 100%;
    height: 96vh;
}

.content-type-description {
    text-align: center;
    font-size: small;
    font-weight: 700;
}

.editor-field {
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 3rem;
    font-family: "Verdana";
    height: auto;
    max-width: 808px;
    min-width: 808px;
    width: 100%;
}

.editor-field-content {
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 3rem;
    font-family: "Verdana";
    z-index: 5;
    max-width: 808px;
    min-width: 808px;
    width: 100%;
    height: 100%;
}

.ck-content {
    padding: 0 16px !important;
}

.ck-toolbar {
    border-radius: 5px !important;
}

.editor-field-content .ck-content {
    min-height: 250px !important;
}

.add-content-button {
    justify-content: flex-start !important;
    width: auto !important;
    padding-left: 10px !important;
    margin: 0 !important;
}

.pdf-action {
    font-size: 15px !important;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    // border: none !important;
    // box-shadow: none !important;
}

.ck.ck-editor__editable_inline:focus {
    // border: none !important;
}

.ck.ck-editor__editable_inline {
    // border: none !important;
}

.pdf-generate-btn {
    background-color: #1976d2 !important;
    color: #fff !important;
    border-radius: 4px !important;
    padding: 6px 16px !important;
}

.pdf-create-btn {
    background-color: #1976d2 !important;
    color: #fff !important;
    border-radius: 4px !important;
}

.pdf-save-btn {
    background-color: #9c27b0 !important;
    color: #fff !important;
    border-radius: 4px !important;
    padding: 6px 16px !important;
}

.disable-upload-btn{
    color: rgba(0, 0, 0, 0.26) !important;
    box-shadow: none !important;
    background-color: rgba(0, 0, 0, 0.12) !important;
}

.pdf-download-btn {
    background-color: #2e7d32 !important;
    color: #fff !important;
    border-radius: 4px !important;
    padding: 6px 16px !important;
}

.confirmation-btn {
    color: #fff !important;
    border-radius: 4px !important;
    padding: 6px 16px !important;
}

.book-heading {
    padding: 24px !important;
}

.radio-btn{
    padding: 9px !important;
}

.MuiPopover-paper > ul {
    display: flex !important;
    flex-direction: column !important;
}

.MuiFormLabel-asterisk {
    color:red !important;
}

.all-epics {
    padding: 6px 16px !important;
    justify-content: flex-start !important;
}

@media (max-width: 1720px) {
    .step-content .MuiCollapse-wrapperInner {
        width: 590px !important;
    }
}

@media (max-width: 1540px) {
    .step-content .MuiCollapse-wrapperInner {
        width: 515px !important;
    }
}

@media (max-width: 1410px) {
    .parent-stepper {
        width: 100%
    }

    .step-content {
        position: static;
        width: 100%;
    }

    .step-content .MuiCollapse-wrapperInner {
        width: 100% !important;
    }
}