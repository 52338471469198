/*
// .site
*/

@import '../variables';
.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.site__header {
    flex-shrink: 0;
    z-index: 1;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.site__body {
    flex-grow: 1;
}

.site__footer {
    flex-shrink: 0;
}