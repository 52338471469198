.subscription-card {
    background-color: #f0f0f0 !important;
    padding: 0px !important;
}

.subscription-card p {
    font-size: 24px;
    font-weight: 500;
}

.subscription-card:hover {
    box-shadow: -1px 9px 20px 2px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: -1px 9px 20px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 9px 20px 2px rgba(0, 0, 0, 0.75);
}

.subscription-card-description {
    background-color: white;
    border-top: 1px solid rgb(202, 201, 201);
    border-bottom: 1px solid rgb(202, 201, 201);
    // margin: 0 -16px;
    text-align: center;
    padding-bottom: 0px;
}

.subscription-card-description p {
    font-size: 20px !important;
    margin-bottom: 2px;
    font-weight: 300;
}

.subscription-card-footer {
    padding-bottom: 5px !important;
    // border-top: 1px solid rgb(202, 201, 201) !important;
    padding-top: 10px !important;
    display: flex;
    justify-content: space-between;
}

.buy-now {
    border-radius: 40px !important;
    border: 1px solid rgb(202, 201, 201) !important;
    // margin-bottom: 0px !important;
}

.view-cart-buuton {
    margin: 60px;
}

.your-cart {
    margin-top: 155px;
}

.your-cart-buttons {
    display: flex;
    justify-content: space-between;
}

.payment-form {
    max-width: "260px";
    margin: 10px;
}

.subscription-cart-titles {
    font-size: 24px;
}

.subscription-quantity {
    letter-spacing: 5px;
}

.subscription-quantity i {
    color: grey;
}

.promo-code {
    border: 1px solid rgb(202, 201, 201);
    margin-bottom: 10px;
    padding: 10px;
}

.promo-code-input {
    border-radius: 22px !important;
    padding-bottom: 8px !important;
    margin-top: 1px !important;
    max-width: 505px !important;
    margin-left: 40px !important;
}

.add-promo-code {
    background-color: transparent !important;
    border-radius: 20px !important;
    border: 1px solid #cfcaca !important;
    color: black !important;
}

.add-promo-code:hover {
    color: black !important;
}

.promoerror {
    margin-left: 55px !important;
}

.userInfo-label {
    margin-left: 4px !important;
    font-weight: 600 !important;
    margin-bottom: 0px !important;
}

.userInfo-input {
    border-radius: 22px !important;
    color: black !important;
}

.rbc-month-row {
    display: flex;
    position: relative;
    flex-direction: column;
    // flex: 1 0;
    // flex-basis: 0px;
    overflow: hidden;
    height: 100%;
    flex: 1 !important;
}

//card
.card:hover {
    box-shadow: 5px 5px lightgray;
}

.product-card:hover {
    margin-bottom: 0px !important;
}

.no-hover .product-card__buttons {
    display: block !important;
}

.slick-track {
    display: block;
}