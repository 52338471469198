.campaignLabel>span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1a9988;
    height: 50px !important;
}

.toggle-wrapper {
    width: 100%;
    margin: 0 !important;
}

.line-height {
    background-color: #e9ecef !important;
    justify-content: center;
    align-items: center;
    padding: 11px;
}

.toggleContainer {
    background-color: #45818e !important;
    border: 1px solid black !important;
}

.toggleContainer .toggleOption.selected {
    color: black !important;
}

.toggleContainer .toggleOption {
    color: white !important;
}

.campaignLabel {
    width: 100%;
    text-align: center !important;
}

.column {
    float: left;
    width: 33.33%;
    padding: 5px;
}

.row::after {
    content: "";
    clear: both;
    display: table;
}

.img {
    cursor: pointer;
    vertical-align: middle;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: #ced4da !important;
    border: 1px solid;
}

.create_campaign {
    background-color: #45818e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.customCoordinateLabel>span {
    background-color: #45818e;
}

.myImage {
    background-color: red;
}

.img:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

button {
    transform-style: preserve-3d;
}

button:after {
    top: -100%;
    left: 0px;
    width: 100%;
    position: absolute;
    background: #ff7a59;
    border-radius: 5px;
    content: 'Flipped';
    transform-origin: left bottom;
    transform: rotateX(90deg);
}

.closeCampaign {
    background-color: #45818e !important;
    position: absolute;
}

.campaignLoader {
    width: 25px !important;
    height: 25px !important;
    margin-right: 15px;
}

.createCampaign {
    width: 100%;
    background-color: #45818e;
    color: white;
    outline: none;
    height: 40px !important;
    border: 1px solid black !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.singlefolio::after {
    background: rgba(43, 130, 230, .8);
    bottom: 0;
    content: "";
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    transition: all .4s ease-out;
    -moz-transition: all .4s ease-out;
    -webkit-transition: all .4s ease-out;
    -o-transition: all .4s ease-out;
}

.singlefolio {
    margin: 0 0 20px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.previewIcon {
    margin: 10px 14px;
}

.previewIcon {
    cursor: pointer !important;
}

.previewIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 17px 0px;
}

.__react_modal_image__header {
    background-color: transparent !important;
}

.__react_modal_image__medium_img {
    max-width: 65% !important;
    max-height: 65% !important;
}

.__react_modal_image__icon_menu a {
    height: 36px;
    width: 41px;
    background: transparent !important;
}

.__react_modal_image__header {
    right: 46%;
    top: 8% !important;
}

.upload__image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload__image-wrapper>button {
    height: 60px;
    margin: 30px 0 10px 0;
}

.image-item {
    margin: 40px 0 20px 0;
}

.image-item__btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.opacity-50 {
    opacity: 0.50;
}

.viewButton {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

.editCampaignButton {
    z-index: 10;
    position: absolute;
    right: 15%;
    top: 5px;
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c7a647;
    color: #c7a647;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: .5s;
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c7a647;
    color: #c7a647;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #c7a647;
    color: #eee69b;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0.3s;
}

.loader_container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loader_wrapper {
    width: 250px;
}

.loader_logo {
    max-width: 100%;
}
.lazySelect  .css-g1d714-ValueContainer{
    height: 50px;
}
.lazySelect  .css-1hwfws3{
    height: 50px;
}

@keyframes dotFlashing {
    0% {
        background-color: #c7a647;
    }
    50%,
    100% {
        background-color: #e4e4e4;
    }
}