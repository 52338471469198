@mixin centerer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.startScreenContent {
    position: relative;
    background: url(./assets/EpicCastle.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
}

.background-part {
    height: 900px;
}

.start-screen__logo {
    width: 60%;
    position: absolute;
    left: 50%;
    // bottom: 100px;
    top: 30%;
    transform: translate(-50%, 0%);
    // padding-top: 200px;
}

.start-screen__motto {
    position: absolute;
    left: 80%;
    top: 75px;
    height: 20%;
    max-height: 175px;
    transform: translate(-50%, 0%);
}

.play-button {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 23%;
    max-width: 400px;
    padding-top: 10px;
    padding-bottom: 10px;
    transform: translate(-50%, -50%);
    font-size: 18pt;
    border-radius: 20px;
    background-color: white;
    color: black;
}

.play-button:hover {
    opacity: 1;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.joinCampaignbutton {
    z-index: 10;
    position: absolute;
    right: 30%;
    top: 5px;
}

.requestButton {
    z-index: 10;
    position: absolute;
    right: 45%;
    top: 5px;
}