// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------

.container {
  max-width: 1902px;
}
@media screen and (max-width: 1440px) {
  .container {
    max-width: 1350px;
  }
}
@media screen and (max-width: 1350px) {
  .container {
    max-width: 1300px;
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 1300px) {
  .container {
    max-width: 1250px;
    margin: 0 auto !important;
  }
}
