.adventurehex {
    background-image: linear-gradient(rgba(68, 9, 9, 0.25), rgba(0, 0, 0, 0.05)), url(./assets/DeepSpaceStarfield-2000.jpg);
    background-size: 1730px;
    height: 1000px;
    overflow: hidden;
}

.adventurehex {
    overflow: hidden;
}

.adventurehex .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113919088_Mayan-Dividers.png');
    background-repeat: repeat-x;
    background-position: 0px -66px;
    z-index: 100;
    font-size: 7pt;
    color: #fff;
    text-align: right;
    padding-top: 6px;
    height: 25px;
}

.initialScreen {
    background-color: transparent;
}

.sidebarContent,
.startScreenContent {
    padding-top: 54px;
}

.hexTileDisplay {
    width: 80px;
    height: fit-content;
    margin: 0px 10px;
    opacity: 0.7;
}

.hexDisplay {
    width: 80px;
    height: fit-content;
    margin: 0px 10px;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.form-group {
    margin-bottom: 1rem !important;
}

.editHexCloseBtn {
    float: right;
    margin-right: 35px;
}

.editHexTabs {
    margin-top: 10px;
    overflow-x: hidden;
}

.mapLoader {
    color: white;
    position: absolute;
    z-index: 1;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.1);
}

.konvajs-content {
    height: 100%;
}

.adventurehex>[role='navigation']:nth-child(3)>div:nth-child(2) {
    z-index: 0 !important;
}

.adventurehex>[role='navigation']:nth-child(4)>div:nth-child(2) {
    z-index: 0 !important;
}

.avatarIconStyle {
    background-image: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -702px -86px;
    width: 286px;
    height: 208px;
}

.bluegemStoneStyle {
    background-image: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -480px -50px;
    width: 102px;
    height: 47px;
    position: fixed;
    top: 111px;
}

.redgemStoneStyle {
    background-image: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -480px -97px;
    width: 102px;
    height: 47px;
    position: fixed;
    top: 55px;
}

.yellowgemStoneStyle {
    background-image: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -484px 0px;
    width: 102px;
    height: 47px;
    position: fixed;
    top: 160px;
}

@media (max-width:1870px) {
    .site_header_image {
        zoom: 70%;
    }
    .nav-panel__indicators {
        // margin-left: 20% !important;
        margin-top: 1%;
        zoom: 80%;
    }
}

@media (max-width:1530px) {
    .nav-panel__indicators {
        margin-left: 15% !important;
        zoom: 70%;
    }
    .avatarIconStyle {
        zoom: 80% !important;
    }
}

@media (max-width:1310px) {
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        margin-right: 50px !important;
        margin-left: 50px !important;
    }
    .avatarIconStyle {
        zoom: 70% !important;
    }
}

@media (max-width:1240px) {
    .site_header_image {
        zoom: 60%;
    }
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
    .nav-panel__indicators {
        margin-top: 2%;
        zoom: 60%;
    }
    .avatarIconStyle {
        zoom: 60% !important;
    }
}

@media (max-width:1140px) {
    .nav-panel__indicators {
        margin-left: 10% !important;
    }
}

@media (max-width:1020px) {
    .nav-panel__indicators {
        margin-left: 7% !important;
    }
}

// .adventurehex>[role='navigation']:nth-child(3)>div:nth-child(2)