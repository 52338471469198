// Avatar Editor Sidebar
// .adventurehex > [role='navigation'] > div:nth-child(1) {
//   z-index: 101 !important;
// }
@font-face {
    font-family: dragon;
    src: url(font/DRAGONFIRE.ttf);
}

$backgroundImage:"https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/MayanV2/20240610112256419_5eAvatarPanelAssets.png";
.avatarSidebar {
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/MayanV2/20240610112654598_bgAvatarPanel_pu9z5s.png');
    // background-position: 546px -298px;
    font-family: dragon;
    // overflow-y: hidden;
    overflow-x: hidden;
    height: -webkit-fill-available;
    color: white;
    padding-left: 149px;
}

.avatarNameStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.saveAvatarStyle {
    background: url($backgroundImage) !important;
    background-position: -20px -382px !important;
    height: 60px !important;
    width: 113px !important;
    margin-left: -7px !important;
    color: white !important;
    border: none !important;
    font-size: 32px !important;
}

.avatarTop {
    margin-left: 7px;
    position: fixed;
    right: 0px;
    z-index: 2;
    top: 2px;
}

.form-control:focus {
    box-shadow: transparent !important;
}

.editAvatarInput {
    background: url($backgroundImage) !important;
    background-position: -810px 3005px !important;
    border-color: gray !important;
    color: White !important;
    border: none !important;
    height: 50px;
    margin-top: -8px;
    background-color: black !important;
    color: white !important;
}

.avatarFormGroup {
    margin-bottom: -8px;
}

.bonusImage {
    margin-top: 20px;
    margin-left: 50px;
    background: url($backgroundImage);
    background-position: -420px -277px;
    height: 45px;
    width: 235px;
    display: flex;
}

.bonusLabel {
    margin-left: 95px;
    margin-top: 11px;
}

.avatarArmor {
    background: url($backgroundImage);
    height: 300px;
    background-position: -48px 452px;
}

.avatarHits {
    background: url($backgroundImage);
    background-position: -232px 449px;
    height: 300px;
}

.qualityList {
    // margin-top: 20px;
    margin-left: 30px;
    height: 50px;
    display: flex;
    width: 235px;
}

.strength {
    background: url($backgroundImage);
    background-position: 0px 446px;
    height: 33px;
    width: 50px;
    margin-left: 10px;
}

.qualityInputNumber {
    background: url($backgroundImage);
    background-position: 0pc 500px;
    height: 40px;
    width: 100px;
    margin-left: 5px;
}

.qualityLabel {
    width: 70px;
    display: flex;
    justify-content: center;
}

.dexerity {
    background: url($backgroundImage);
    background-position: 0px 395px;
    height: 33px;
    width: 50px;
    margin-left: 10px;
}

.constitution {
    background: url($backgroundImage);
    background-position: 0px 347px;
    height: 33px;
    width: 50px;
    margin-left: 10px;
}

.intelligence {
    background: url($backgroundImage);
    background-position: 0px 300px;
    height: 33px;
    width: 50px;
    margin-left: 10px;
}

.wisdom {
    background: url($backgroundImage);
    background-position: 0px 252px;
    height: 33px;
    width: 50px;
    margin-left: 10px;
}

.charisma {
    background: url($backgroundImage);
    background-position: 0px 203px;
    height: 33px;
    width: 50px;
    margin-left: 10px;
}

.avatarImage {
    height: 275px;
    width: 255px;
    margin-left: 20px;
    background: url($backgroundImage);
    background-position: -415px -3px;
}