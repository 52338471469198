/*
// .block-products-carousel
*/

@import '../variables';
@import '../functions';
@import '../mixins/product-card';
@import '../mixins/preloader';
.block-products-carousel {
    .slick-track {
        padding-bottom: 56px;
        display: flex;
        align-items: stretch;
        margin-left: 0;
    }
    .slick-list {
        // margin: 0 -5px;
        margin-bottom: -56px;
        &:hover {
            z-index: 2;
        }
    }
    .slick-slide {
        padding: 0 5px;
        display: flex;
        &:focus,
        .correct-slick-active {
            outline: none;
        }
        &>div,
        &>div>div {
            width: 100%;
            display: flex !important;
            align-items: stretch;
        }
    }
}

.block-products-carousel__no-records {
    .slick-track {
        padding-bottom: 56px;
        display: flex;
        align-items: stretch;
        margin-left: 0;
        justify-content: center;
        width: 100% !important;
        margin-top: 190px;
    }
    .slick-list {
        // margin: 0 -5px;
        margin-bottom: -56px;
        &:hover {
            z-index: 2;
        }
    }
    .slick-slide {
        width: 100% !important;
        padding: 0 5px;
        display: flex;
        &:focus,
        .correct-slick-active {
            outline: none;
        }
        &>div,
        &>div>div {
            width: 100%;
            display: flex !important;
            align-items: stretch;
            justify-content: center;
            font-size: xx-large;
            font-weight: 800;
        }
    }
}

.block-products-carousel__slider {
    position: relative;
    min-height: 200px;
}

.block-products-carousel__no-records {
    position: relative;
    min-height: 420px;
}

.invitePlayer {
    width: 15%;
}

.customPlayer {
    width: 85%;
}

.customBook {
    width: 50%;
}

@media (min-width: 1201px) {
    .customPlayer {
        width: 83%;
    }
    .invitePlayer {
        width: 15%;
    }
}

@media (max-width: 1200px) {
    .customPlayer {
        width: 75%;
    }
}

@media (max-width: 991px) {
    .customPlayer {
        width: 75%;
    }
    .invitePlayer {
        width: 24%;
    }
}

@media (max-width: 767px) {
    .customPlayer {
        width: 65%;
    }
    .invitePlayer {
        width: 32%;
    }
}

.block-products-carousel__preloader {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: $block-products-carousel-preloader-bg;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s, visibility 0s .3s;
    &:after {
        @include preloader(100px);
    }
}

.block-products-carousel--loading .block-products-carousel__preloader {
    transition-delay: 0s, 0s;
    opacity: 1;
    visibility: visible;
}

.block-products-carousel__column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.block-products-carousel__cell {
    width: 100%;
    flex-grow: 1;
    display: flex;
    &+& {
        margin-top: 10px;
    }
    .product-card {
        width: 100%;
    }
}

.block-products-carousel--has-items {
    .block-products-carousel__preloader {
        bottom: 0
    }
}

.block-products-carousel[data-layout^="grid-"] {
    .product-card {
        @include product-card-grid();
    }
}

.block-products-carousel[data-layout="grid-4"] {
    .product-card {
        @include product-card-grid-nl();
    }
    @media (min-width: 480px) and (max-width: breakpoint(lg-end)) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
}

.block-products-carousel[data-layout="grid-4-sm"] {
    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: 479px) {
        .product-card {
            @include product-card-grid-nl();
        }
    }
}

.block-products-carousel[data-layout="grid-5"] {
    @media (min-width: 480px) {
        .product-card {
            @include product-card-grid-sm();
        }
    }
    @media (max-width: 479px) {
        .product-card {
            @include product-card-grid-nl();
        }
    }
}

.block-products-carousel[data-layout="horizontal"] {
    .product-card {
        @include product-card-horizontal();
    }
}