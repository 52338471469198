.hexSidebar {
    padding-top: 14px;
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: 0px 0px;
    height: 100%;
    overflow-y: hidden;
    font-family: dragon;
}

.editHexButton {
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -790px 2470px;
    padding: 7px;
    margin-left: 10%;
    width: 125px;
    border: none;
    color: white
}

.editHexFilter {
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png') !important;
    background-position: -826px 2578px !important;
    width: 275px !important;
    height: 30px !important;
    margin-top: 7px !important;
    border: none !important;
    color: white !important;
}

.editHexFilterOption {
    color: black !important;
}

.hexImages {
    max-height: 705px;
    overflow-y: scroll;
    scrollbar-color: black;
    padding-bottom: 30px;
    margin-right: 63px;
}

.hexImages::-webkit-scrollbar {
    width: 8px;
}

.hexImages::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #174c17;
}

.editHexInput {
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png') !important;
    background-position: -790px 2815px !important;
    border-color: gray !important;
    color: White !important;
}

.editAvatarData {
    color: white;
    padding-right: 47px;
    overflow: hidden;
}

* {
    -ms-overflow-style: none;
}

.editAvatarData ::-webkit-scrollbar {
    display: none;
}