.App {
  font-family: sans-serif;
  text-align: center;
}

:root {
  --base: #8c7aa9;
  --text: #fff;
  --primary-button: #7192be;
  --button-border: #7192be;
  --shadow: #eda2f2;
}

.signature-canvas,
.signature-image {
  border: 1px solid var(--button-border);
  margin: 1rem;
  border-radius: 25px;
}

.sign-buttons button {
  background-color: var(--primary-button);
  color: var(--text);
  padding: 0.25rem;
  min-width: 6rem;
  margin: 0.5rem;
  border: 1px solid var(--button-border);
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
}

.sign-buttons button:hover {
  background-color: var(--text);
  box-shadow: 0px 2px 4px var(--shadow);
  color: var(--primary-button);
}
