/*
// .nav-panel
*/

@import '../variables';
@import '../functions';
@import '../mixins/direction';
.nav-panel {
    height: $nav-panel-height;
    background: $nav-panel-bg;
    box-shadow: $nav-panel-shadow;
    color: $nav-panel-font-color;
}

.nav-panel--stuck {
    z-index: 10;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: $nav-panel-stuck-shadow;
}

.user_profile {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_image {
    border-radius: 50px;
    width: 30px;
    margin-right: 8px;
}

.nav-panel__container {
    height: 100%;
}

.nav-panel__row {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
}

.nav-links__item {
    z-index: 5;
}

.site_header_image {
    position: absolute;
    z-index: 3;
    width: 196px;
    transform: translateX(-50%);
    left: 50%;
    top: 0%;
    height: 125px;
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -713px 2760px;
    margin: auto;
    display: none;
}

.nav-panel__logo svg {
    display: block;
    fill: $nav-panel-logo-color;
}

.nav-panel__departments {
    flex-shrink: 0;
}

.nav-panel__logo+.nav-panel__nav-links,
.nav-panel__departments+.nav-panel__nav-links {
    @include direction {
        #{$margin-inline-start}: 18px;
    }
}

.nav-panel__nav-links {
    @include direction {
        #{$margin-inline-end}: 18px;
    }
}

.nav-panel__indicators {
    display: flex;
    height: 100%;
    @include direction {
        #{$margin-inline-start}: auto;
    }
}

// ////////////////LOGIN DETAILS////////////////////////////////////
.login_details_style {
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -732px 2815px;
    width: 365px;
    margin-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'dragon'
}

.login_image_style {
    background: url('https://pub-9bedfad36ab4490eb3a8192d4681c73e.r2.dev/apps/adventurehex/themes/20240610113828487_Mayan-UI.png');
    background-position: -983px 2643px;
    height: 50px;
    width: 100px;
    margin-left: 10px;
    cursor: pointer;
}

// ////////////////////////////////////////////////////////////////////
@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
    .nav-panel__nav-links {
        @include direction {
            #{$margin-inline-start}: 14px;
            #{$margin-inline-end}: 14px;
        }
    }
}