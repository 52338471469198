/*
// .modal
*/

@import '../variables';
.modal {
    -webkit-overflow-scrolling: touch;
}

.modal-content {
    border-radius: $modal-border-radius;
}

.modal-backdrop.show {
    opacity: 0.1;
}

.close {
    border-radius: 2px;
    transition: all 0.1s ease-in-out;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: 0.375rem 1.25rem;
    font-weight: 500;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}