/*
// .block-brands
*/
@import '../variables';
@import '../functions';
@import '../mixins/card';


.block-brands {}
.block-brands__slider {
    @include card();

    .slick-slide > div > div {
        vertical-align: middle;

        &:focus {
            outline: none;
        }
    }
}
.block-brands__item {
    padding: 24px 24px;

    img {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: breakpoint(sm-end)) {
    .block-brands__item {
        padding: 20px 24px;
    }
}
